<template>
    <div class="year-data-analysis-box">
        <div class="year-data-analysis">
            <header class="header">
                <span></span>
                <span>数据钻取分析</span>
                <span class="iconfont" @click="closeYearAlert">&#xe67d;</span>
            </header>
            <div class="year-body">
                <div class="body-box">
                    <header class="year-body-header">
                        <span>各类合同销售方量统计</span>
                        <span>单位：(方)</span>
                    </header>
                    <div class="echarts-position-div" :style="{color: selectColorChild}">
                        <p v-if="salesVolumeTextSubText">
                            {{ salesVolumeTextSubText }}%
                        </p>
                        <p>{{ salesVolumeText }}</p>
                    </div>
                    <div style="width:100%;height:240px" id="childSalesVolume"></div>
                    <div class="one-echarts-right">
                        <div class="one-echarts-right-header">
                            {{ clickName }}合计：<span>{{ flTotalNumber.toFixed(2) }}</span>
                        </div>
                        <div class="one-echarts-right-body">
                            <div v-for="(item, index) in monthListData" :key="index">
                                <span>{{ item.name }}</span>
                                <p>{{ item.xsfl }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="body-box">
                    <header class="year-body-header">
                        <span>各类合同销售金额统计</span>
                        <span>单位：(元)</span>
                    </header>
                    <div style="width:100%;height:240px" id="childSalesAmount"></div>
                    <p class="child-salesAmount-p">
                        {{ clickName }}合计：<span>{{ jeTotalNumber.toFixed(2) }}</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import bg12 from '../../../../../assets/images/gk/bg12.png';
import bg13 from '../../../../../assets/images/gk/bg13.png';
import bg14 from '../../../../../assets/images/gk/bg14.png';
import bg15 from '../../../../../assets/images/gk/bg15.png';
import circle1 from '../../../../../assets/images/gk/circle1.png';
import circle2 from '../../../../../assets/images/gk/circle2.png';
import circle3 from '../../../../../assets/images/gk/circle3.png';
import circle4 from '../../../../../assets/images/gk/circle4.png';
export default {
    data() {
        return {
            flTotalNumber: 0,
            jeTotalNumber: 0,
            monthListData: [],
            salesVolumeText: '',
            salesVolumeTextSubText: '',
            selectColorChild: '',
            salesVolumeData: [],
            newClickName: '',
            echartsColor: ['#EA8343', '#4CA295', '#4BA2DA', '#3E78C3', '#f2719a', '#fca4bb', '#f59a8f', '#fdb301', '#57e7ec', '#cf9ef1'],
        };
    },
    props: {
        clickName: {
            type: String,
        },
        clickEchartsId: {
            type: String,
        },
    },
    mounted() {
        this.newClickName = this.heckAllCnText(this.clickName);
        this.salesanalysisMonth();
    },
    methods: {
        closeYearAlert() {
            this.$emit('closeYearAlert');
        },
        heckAllCnText(str) {
            const reg = /[\u4E00-\u9FA5]/g;
            if (reg.test(str)) {
                const aaa = str.replace(reg, '-');
                return aaa.slice(0, aaa.length - 1);
            }
            return str;
        },
        // 各类合同销售方量统计
        salesVolumeEcharts() {
            const that = this;
            const myChart = this.$echarts.init(document.getElementById('childSalesVolume'));
            const option = {
                color: that.echartsColor,
                legend: {
                    bottom: 0,
                    itemWidth: 8,
                    itemHeight: 8,
                },
                series: [{
                    type: 'pie',
                    minAngle: 1, // 最小的扇区角度（0 ~ 360）
                    radius: ['40%', '70%'],
                    center: ['25%', '50%'],
                    avoidLabelOverlap: false,
                    itemStyle: { // 图形样式
                        normal: {
                            borderColor: '#ffffff',
                            borderWidth: 6,
                        },
                    },
                    label: {
                        normal: {
                            show: false,
                            position: 'center',
                            formatter: '{text|{b}}\n{c} ({d}%)',
                            rich: {
                                text: {
                                    color: '#666',
                                    fontSize: 14,
                                    align: 'center',
                                    verticalAlign: 'middle',
                                    padding: 8,
                                },
                                value: {
                                    color: '#8693F3',
                                    fontSize: 24,
                                    align: 'center',
                                    verticalAlign: 'middle',
                                },
                            },
                        },
                    },
                    data: that.salesVolumeData,
                }],
            };
            myChart.setOption(option);

            setTimeout(function () {
                myChart.on('mouseover', function (params) {
                    if (params.name === that.salesVolumeData[0].name) {
                        myChart.dispatchAction({
                            type: 'highlight',
                            seriesIndex: 0,
                            dataIndex: 0,
                        });
                        that.salesVolumeText = params.name;
                        that.salesVolumeTextSubText = params.percent;
                    } else {
                        myChart.dispatchAction({
                            type: 'downplay',
                            seriesIndex: 0,
                            dataIndex: 0,
                        });
                        that.salesVolumeText = params.name;
                        that.salesVolumeTextSubText = params.percent;
                    }
                    that.selectColorChild = params.color;
                });

            }, 1000);
        },
        // 各类合同销售金额统计
        salesAmountEcharts(data) {
            // eslint-disable-next-line max-len
            const dashedPic = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAM8AAAAOBAMAAAB6G1V9AAAAD1BMVEX////'
            + 'Kysrk5OTj4+TJycoJ0iFPAAAAG0lEQVQ4y2MYBaNgGAMTQQVFOiABhlEwCugOAMqzCykGOeENAAAAAElFTkSuQmCC';
            const myChart = this.$echarts.init(document.getElementById('childSalesAmount'));
            const color = ['#009DFF', '#FF8700', '#73C7B1', '#78CAF9'];
            const chartData = data;
            const arrName = [];
            const arrValue = [];
            let sum = 0;
            const pieSeries = [];
            const lineYAxis = [];

            // 数据处理
            chartData.forEach(v => {
                arrName.push(v.name);
                arrValue.push(v.value);
                sum = sum + v.value;
            });

            // 图表option整理
            chartData.forEach((v, i) => {
                pieSeries.push({
                    type: 'pie',
                    clockWise: false,
                    hoverAnimation: false,
                    radius: [65 - i * 15 + '%', 57 - i * 15 + '%'],
                    center: ['30%', '50%'],
                    label: {
                        show: false,
                    },
                    data: [{
                        value: v.value,
                        name: v.name,
                    }, {
                        value: sum - v.value,
                        name: '',
                        itemStyle: {
                            color: 'rgba(0,0,0,0)',
                        },
                    }],
                });
                pieSeries.push({
                    name: '',
                    type: 'pie',
                    silent: true,
                    z: 1,
                    clockWise: false, // 顺时加载
                    hoverAnimation: false, // 鼠标移入变大
                    radius: [65 - i * 15 + '%', 57 - i * 15 + '%'],
                    center: ['30%', '50%'],
                    label: {
                        show: false,
                    },
                    data: [{
                        value: 7.5,
                        itemStyle: {
                            color: '#E3F0FF',
                        },
                    }, {
                        value: 2.5,
                        name: '',
                        itemStyle: {
                            color: 'rgba(0,0,0,0)',
                        },
                    }],
                });
                v.percent = (v.value / sum * 100).toFixed(1) + '%';
                lineYAxis.push({
                    value: i,
                    textStyle: {
                        rich: {
                            circle: {
                                color: color[i],
                                padding: [0, 5],
                            },
                        },
                    },
                });
            });
            const option = {
                backgroundColor: '#fff',
                color: color,
                grid: {
                    top: '15%',
                    bottom: '54%',
                    left: '30%',
                    right: '3%',
                    containLabel: false,
                },
                yAxis: [{
                    type: 'category',
                    inverse: true,
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        formatter: function (params) {
                            const item = chartData[params];
                            let str = '';
                            switch (params) {
                                case 0:
                                    str = '{line|}{circle1|}{name|' + item.name + '} {percent1|' + item.percent + '} {percent1|' + item.value + '}';
                                    break;
                                case 1:
                                    str = '{line|}{circle2|}{name|' + item.name + '} {percent2|' + item.percent + '} {percent2|' + item.value + '}';
                                    break;
                                case 2:
                                    str = '{line|}{circle3|}{name|' + item.name + '} {percent3|' + item.percent + '} {percent3|' + item.value + '}';
                                    break;
                                case 3:
                                    str = '{line|}{circle4|}{name|' + item.name + '} {percent4|' + item.percent + '} {percent4|' + item.value + '}';
                                    break;
                            }
                            return str;
                        },
                        // formatter: function (params) {
                        //     const item = chartData[params];
                        //     return '{line|}{circle|●}{name|' + item.name + '}{bd||}  {percent|' + item.percent + '}  {value|' + item.value + '}';
                        // },
                        interval: 0,
                        inside: true,
                        textStyle: {
                            rich: {
                                circle1: {
                                    backgroundColor: { image: circle4 },
                                },
                                circle2: {
                                    backgroundColor: { image: circle3 },
                                },
                                circle3: {
                                    backgroundColor: { image: circle2 },
                                },
                                circle4: {
                                    backgroundColor: { image: circle1 },
                                },
                                line: {
                                    width: 60,
                                    backgroundColor: { image: dashedPic },
                                },
                                name: {
                                    width: 85,
                                    fontSize: 14,
                                    margin: [10],
                                    padding: [0, 0, 0, 10],
                                },
                                percent1: {
                                    color: '#3F7BC7',
                                    padding: [0, 5],
                                    fontSize: 12,
                                    backgroundColor: { image: bg15 },
                                    height: 20,
                                    align: 'center',
                                    // verticalAlign: 'bottom',
                                },
                                percent2: {
                                    color: '#FC863C',
                                    fontSize: 12,
                                    padding: [0, 5],
                                    backgroundColor: { image: bg14 },
                                    height: 20,
                                    align: 'center',
                                },
                                percent3: {
                                    color: '#4EA396',
                                    fontSize: 12,
                                    padding: [0, 5],
                                    backgroundColor: { image: bg13 },
                                    height: 20,
                                    align: 'center',
                                },
                                percent4: {
                                    color: '#2AA0E9',
                                    fontSize: 12,
                                    padding: [0, 5],
                                    backgroundColor: { image: bg12 },
                                    height: 20,
                                    align: 'center',
                                },
                                value: {
                                    color: '#333',
                                    fontSize: 12,
                                    backgroundColor: { image: bg12 },
                                    height: 20,
                                    align: 'center',
                                },
                            },
                        },
                        show: true,
                    },
                    data: lineYAxis,
                }],
                xAxis: [{
                    show: false,
                }],
                series: pieSeries,
            };
            myChart.setOption(option);
        },
        // 接口
        salesanalysisMonth() {
            this.$axios
                .get(`/interfaceApi/report//salesanalysis/xsqk/htlx/month?date=${this.newClickName}&id=${this.clickEchartsId}`)
                .then(res => {
                    // // 各类合同销售方量
                    this.monthListData = res.list;
                    const jeData = [];
                    res.list.forEach(item => {
                        this.flTotalNumber += item.xsfl;
                        this.jeTotalNumber += item.xsje;
                        const circularData = {
                            name: item.name,
                            value: item.xsfl,
                        };
                        const moneyData = {
                            name: item.name,
                            value: item.xsje,
                        };
                        jeData.push(moneyData);
                        this.salesVolumeData.push(circularData);
                    });
                    const oneDataNumber = this.salesVolumeData[0].value / this.flTotalNumber * 100;
                    this.salesVolumeText = this.salesVolumeData[0].name;
                    this.salesVolumeTextSubText = oneDataNumber.toFixed(1);
                    this.selectColorChild = this.echartsColor[0];
                    this.salesVolumeEcharts();
                    this.salesAmountEcharts(jeData);
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.message);
                });
        },
    },
};

</script>
<style lang='stylus' scoped>
.year-data-analysis-box
    height 100%
    position fixed
    top 0
    left 0
    right 0
    bottom 0
    background rgba(0,0,0,0.4)
    z-index 99
    display flex
    justify-content center
    align-items center
    .year-data-analysis
        width 14rem
        display flex
        flex-direction column
        background #fff
        max-height 93%
        min-height 5rem
        .header
            display flex
            justify-content space-between
            align-items center
            height .5rem
            background #5368a8
            color #fff
            font-size .2rem
            padding 0 .2rem
            span:last-child
                cursor pointer
                font-size .22rem
        .year-body
            display flex
            height 100%
            .body-box
                flex 1
                box-shadow:0px .02rem .06rem 0px rgba(85,136,241,0.5);
                width 100%
                padding .2rem
                position relative
                &:first-child
                    margin .2rem .12rem 0 .2rem
                &:last-child
                    margin .2rem .2rem 0 .12rem
                .echarts-position-div
                    position: absolute;
                    left: 1.15rem;
                    z-index: 9;
                    top 50%
                    transform translateY(-40%)
                    text-align center
                    font-family QuartzLTStd
                    width 1.3rem
                .child-salesAmount-p
                    position: absolute;
                    bottom: .2rem;
                    right: .2rem;
                    z-index: 9;
                    text-align center
                    font-size .16rem
                    span
                        font-size .28rem
                        margin-top .01rem
                        font-family QuartzLTStd
                .one-echarts-right
                    width 50%
                    position absolute
                    right 0
                    top 1rem
                    .one-echarts-right-header
                        color #333333
                        font-size .16rem
                        height .25rem
                        display flex
                        align-items center
                        margin-left .2rem
                        span
                            font-size .28rem
                            font-family QuartzLTStd
                            padding-top .1rem
                    .one-echarts-right-body
                        display flex
                        flex-wrap wrap
                        margin-left .2rem
                        div
                            width 50%
                            margin-top .1rem
                            span
                                color #666666
                                font-size .16rem
                            p
                                font-family QuartzLTStd
                                font-size .24rem
                                background-size cover
                                width .86rem
                                height .29rem
                                display flex
                                align-items center
                                justify-content center
                                padding-top .1rem
                                margin-top .01rem
                            &:nth-child(1) p
                                color #FC863C
                                background-image url(../../images/bg1.png)
                            &:nth-child(2) p
                                color #4DA396
                                background-image url(../../images/bg2.png)
                            &:nth-child(3) p
                                color #3E7BC6
                                background-image url(../../images/bg3.png)
                            &:nth-child(4) p
                                color #45AAEB
                                background-image url(../../images/bg4.png)
                .year-body-header
                    display flex
                    justify-content space-between
                    span:first-child
                        color #333333
                        font-weight 800
                        font-size .18rem
                        &:before
                            content '1'
                            width .06rem
                            height .2rem
                            color #1577D2
                            background #1577D2
                            margin-right .1rem
                    span:last-child
                        color #979EA7
                        font-size .14rem

</style>